.dashboard {
  background-color: #f4f4f4;
  min-height: 100vh;
  width: 100%;
}

.dashboard-body {
  padding: 32px 60px 0;
  min-height: 92vh;
  display: flex;
  flex-direction: column;

  .footer {
    width: calc(
      100% + 120px
    ); /* 100% + left and right padding of .dashboard-body */
    margin-left: -60px;
  }
}
