@import "../../../assets/globalStyle/variables.scss";

.search,
.search_lg {
  flex: 1;
  max-width: 300px;

  .search-prepend-focused,
  .search-focused {
    background-color: $white !important;
    border: 1px solid $primary !important;
  }

  .search-focused {
    border-left: none !important;
  }

  .search-prepend-focused {
    border-right: none !important;
  }
}

.form-control {
  transition: none;
}

.search input:focus,
.search_lg input:focus {
  outline: none;
  box-shadow: none;
}

.search .input-group,
.search .input-group-prepend .input-group-text i,
.search .form-control::placeholder,
.search_lg .input-group,
.search_lg .input-group-prepend .input-group-text i,
.search_lg .form-control::placeholder,
input::placeholder {
  color: #6f767e !important;
}

::-webkit-input-placeholder {
  /* Safari, Chrome, and Opera */
  color: #6f767e;
}

.search input {
  height: 40px !important;
}

.search_lg input {
  height: 48px !important;
}

.search .input-group-prepend .input-group-text,
.search_lg .input-group-prepend .input-group-text {
  height: 40px;
  border-radius: 24px 0 0 24px;
  border: none;
  background-color: #f4f4f4;
  padding-right: 0;
}

.search_lg .input-group-prepend .input-group-text {
  height: 48px !important;
}

.search .form-control,
.search_lg .form-control {
  border-radius: 24px;
  border: none;
  background-color: #f4f4f4;
  color: #6f767e;
}

@media screen and (max-width: 600px) {
  .search,
  .search_lg {
    max-width: 100%;
    flex: none;
  }
}
