//   Buttons
.btn {
    margin-top: 24px;
    border-radius: 24px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
    min-width: max-content;
    font-weight: 600 !important;
  }
  
  .btn-primary {
    background-color: $primary;
    border: none;
  }
  
  .btn-primary:hover,
  .btn-primary:active {
    background-color: #5ea8f8;
  }
  
  .btn-primary:disabled {
    background-color: #9a9fa5;
    opacity: 1;
  }
  
  .btn-outline-dark {
    border: 1px solid var(--50, #c0c4c9);
    color: $dark;
  }
  
  .btn-outline-dark:hover,
  .btn-outline-dark:active {
    background-color: $light;
    border: 1px solid var(--50, #c0c4c9);
    color: $dark;
  }
  
  .btn-outline-danger {
    border: 1px solid $danger;
    color: $danger;
  }
  
  .btn-outline-danger:hover,
  .btn-outline-danger:active {
    background-color: $danger;
    border: 1px solid $danger;
    color: #fff;
  }
  
  .btn-outline-primary {
    border: 1px solid $primary;
    color: $primary;
  }
  
  .btn-outline-primary:hover,
  .btn-outline-primary:active {
    background-color: $primary;
    border: 1px solid $primary;
    color: #fff;
  }
  