.info-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.info-card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 24px;
  width: 100%;
  max-width: 270px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
  .info-card-detail {
    display: flex;
    flex-direction: column;
  }
  .heading {
    font-weight: 600;
    font-size: 24px;
  }
}
