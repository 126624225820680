@import "../../../assets/globalStyle/variables.scss";

.profile-image,
.holiday-circle {
  width: 28px;
  height: 28px;
  flex-shrink: 0;
  border-radius: 50%;
  color: #374957;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-image > span,
.calender-icon {
  color: #fff;
  font-size: 10px;
  font-weight: 600;
  line-height: 20px; /* 200% */
}

.no-content-circle {
  width: 86px;
  height: 86px;
  border-radius: 50%;
  display: flex;
  background-color: $light;
  align-items: center;
  justify-content: center;
  i {
    font-size: 32px;
    color: $dark;
  }
}

.image-icon {
  width: 60px;
  height: 60px;
  border: 1px solid #efefef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

// .holiday-circle{
//     width : 48px;
//     height: 48px;
//     border-radius: 24px;
// }

// .calender-icon{
//     font-size: 18px;
// }
