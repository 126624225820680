
.error-div, .black-error-div{
    color: var(--Ab-16, #BE0F00);
    font-weight: 500;
    font-size: 12px;
    margin-top: 5px;
    display: flex;
}

.black-error-div{
  color: #1E273B;
}