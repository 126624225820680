@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap");
@import "authLayout";
@import "custom";
@import "variables";
@import "card";
@import "dashboardLayout";
@import "btns";

:root {
  font-family: "Nunito Sans";
  line-height: 1.5;
  font-synthesis: none;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: auto;
}

#root {
  font-family: "Nunito Sans";
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// Toast React Hot Toast Customization
.go2344853693:after {
  border-color: #1e273b !important ;
}

.go2534082608:after,
.go2534082608:before {
  background: rgb(190, 15, 0) !important;
}

.go2344853693:after {
  border-color: #83bf6e !important;
}

.go2344853693,
.go2534082608 {
  background-color: #fff !important;
}
.footer {
  margin-top: auto; /* Push the footer to the bottom of the content */
  height: 80px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 20px;
  padding: 0 72px;
}

.view-muted,
.view-muted-16 {
  color: var(--80, #6f767e);
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.14px;
}
