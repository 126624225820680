.auth-screen {
  display: flex;
  height: 100vh;
  width: 100%;
}

.auth-image {
  flex: 50%;
  background-image: url("../images/Group\ 162458.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  // box-shadow: inset linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.20) 20.43%, rgba(0, 0, 0, 0.00) 100%);
  // box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 0.2), inset 0 0 0 0vmax rgba(0, 0, 0, 0.1);
}

.auth-content {
  flex: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.auth-child {
  flex: 1;
  max-width: 390px;
  min-width: 0;
  width: 100%;
  box-sizing: border-box;
}

.logo-image {
  width: 167px !important;
  margin-top: 20px;
  margin-left: 12%;
}

@media (max-width: 768px) {
  .auth-image {
    display: none; /* Hide the auth-image on smaller screens */
  }

  .auth-content {
    flex: 100%; /* Take up the entire width of the screen */
  }
}
