.page-card {
  width: 100%;
  height: auto;
  border-radius: 12px; /* 12px / 16px */
  border-bottom: 1px solid var(--30, #efefef);
  background: #fff;
  padding: 2rem; /* 32px / 16px */
}

.page-card-disabled {
  pointer-events: none;
  cursor: not-allowed !important;
  opacity: 0.7;
}

.extra-card {
  width: 30%; /* 30% / 16px */
}

.no-content-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 6%;
  padding-bottom: 5%;

  img {
    height: 11rem;
  }
}

.no-content-title {
  color: #333;
  text-align: center;
  font-size: 1.125rem; /* 18px / 16px */
  font-weight: 700;
  line-height: 1.25rem; /* 20px / 16px */
  // margin-bottom: 0.75rem; /* 12px / 16px */
}

.no-content-subtitle {
  color: #697184;
  font-size: 0.875rem; /* 14px / 16px */
  font-weight: 500;
}

.form-card {
  width: 68.5%;
}

@media screen and (max-width: 68.75rem) {
  /* 1100px / 16px */
  .form-card {
    width: 100%;
  }
}
