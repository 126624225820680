.rs-picker-daterange-menu {
  &.report-data-picker-container-dashboard {
    left: auto !important;
    right: 8vw !important;
  }
  .rs-picker-menu {
    .rs-calendar {
      min-width: 241px !important;
    }
  }
  .rs-stack-item {
    padding: 5px !important;
  }
}
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
.rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
.rs-picker-daterange .rs-picker-toggle.rs-btn {
  top: 0px !important;
}
// .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
// .rs-picker-date .rs-picker-toggle.rs-btn .rs-picker-toggle-clean,
// .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret,
// .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-clean {
//   top: 5px !important;
// }

.rs-stack {
  margin-top: 7.5px !important;
  .rs-picker-toggle-placeholder {
    color: hsl(0, 0%, 50%) !important;
    font-size: 14px !important;
    transition: box-shadow 0.2s ease !important;
    font-weight: 500 !important;
  }
}

.charts-datapicker-container {
  .rs-picker-toggle {
    .rs-stack {
      margin-top: 0px !important;
    }
  }
}
.rs-anim-fade,
.rs-anim-in,
.rs-picker-popup-daterange,
.rs-picker-popup,
.placement-bottom-start {
  right: 77px !important;
  left: auto !important;
}
